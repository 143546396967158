import { NavLink, Routes, Route } from "react-router-dom"
import MyServicesComponent from "./my-services/my-services.component"
import BalanceComponent from "./balance/balance.component"
import style from './personal.module.scss'
import logo_white from '../../assets/img/logo_white.svg'
import PaymentsUnsuccessfulComponent from "./_static-pages/payments-unsuccessful/payments-unsuccessful.component"
import PaymentsSuccessfulComponent from "./_static-pages/payments-successful/payments-successful.component"
import ContractComponent from "./contract/contract.component"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { getLocalStorageItem } from "../../services/localStore.service"
import { AppDispatch, userDataAsyncSlice } from "../../store/features/user-data.slice"
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter'
import CurrencyRubleIcon from '@mui/icons-material/CurrencyRuble'
import BlockIcon from '@mui/icons-material/Block'
import PolicyIcon from '@mui/icons-material/Policy'

const PersonalComponent = () => {

    // const {custom} = useTheme()
    // const navigate = useNavigate()
    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        const userIndex = JSON.parse(getLocalStorageItem('user'))?.index
        if (userIndex) {
            dispatch(userDataAsyncSlice({ index: userIndex }))
        }
    }, [])


    return (
        <div className={style.personal}>
            <div className={style.personal__menu}>
                <div className={style.personal__menu_link}>
                    <div className={style.personal__menu_link_block}>

                        <NavLink
                            className={({ isActive }) => isActive
                                ? (style.personal__menu_link_block_item_act + ' fnt-mull-500')
                                : (style.personal__menu_link_block_item_noact + ' fnt-mull-500')}
                            to={'/'}
                        >
                            <div className={style.menu_txt}>Мои услуги</div>
                            <span className={style.menu_icon}><BusinessCenterIcon></BusinessCenterIcon></span></NavLink>

                        <NavLink
                            className={({ isActive }) => isActive
                                ? (style.personal__menu_link_block_item_act + ' fnt-mull-500')
                                : (style.personal__menu_link_block_item_noact + ' fnt-mull-500')}
                            to={'balance'}
                        >
                            <div className={style.menu_txt}>Пополнить баланс</div>
                            <span className={style.menu_icon}><CurrencyRubleIcon></CurrencyRubleIcon></span></NavLink>

                        <NavLink
                            to={''}
                            className={style.personal__menu_link_block_disabled + ' fnt-mull-500'}
                        >
                            <div className={style.menu_txt}>Дооборудование</div>
                            <span className={style.menu_icon}><BlockIcon></BlockIcon></span></NavLink>

                        <NavLink
                            to={''}
                            className={style.personal__menu_link_block_disabled + ' fnt-mull-500'}
                        >
                            <div className={style.menu_txt}>Уведомления</div>
                            <span className={style.menu_icon}><BlockIcon></BlockIcon></span></NavLink>

                        <NavLink
                            to={''}
                            className={style.personal__menu_link_block_disabled + ' fnt-mull-500'}
                        >
                            <div className={style.menu_txt}>Доступы</div>
                            <span className={style.menu_icon}><BlockIcon></BlockIcon></span></NavLink>

                        <NavLink
                            to={''}
                            className={style.personal__menu_link_block_disabled + ' fnt-mull-500'}
                        >
                            <div className={style.menu_txt}>Сервис</div>
                            <span className={style.menu_icon}><BlockIcon></BlockIcon></span></NavLink>
                    </div>
                    <div className={style.personal__menu_link_block}>
                        <NavLink
                            to={''}
                            className={style.personal__menu_link_block_disabled + ' fnt-mull-500'}
                        >
                            <div className={style.menu_txt}>Помощь</div>
                            <span className={style.menu_icon}><BlockIcon></BlockIcon></span></NavLink>
                        <NavLink
                            to={''}
                            className={style.personal__menu_link_block_disabled + ' fnt-mull-500'}
                        >
                            <div className={style.menu_txt}>Контакты</div>
                            <span className={style.menu_icon}><BlockIcon></BlockIcon></span></NavLink>
                    </div>
                    <div className={style.personal__menu_link_block}>
                        <NavLink
                            className={({ isActive }) => isActive
                                ? (style.personal__menu_link_block_item_act + ' fnt-mull-500')
                                : (style.personal__menu_link_block_item_noact + ' fnt-mull-500')}
                            to={'/privacy-policy'}
                        >
                            <div className={style.menu_txt}>Политика Конфиденциальности!</div>
                            <span className={style.menu_icon}><PolicyIcon></PolicyIcon></span></NavLink>

                        {/*TODO: добавить в будущем*/}
                        {/*<div className={style.personal__menu_link_block_item_link + ' fnt-mull-500'}*/}
                        {/*     style={{color: custom.static.disabled}}*/}
                        {/*>Условия Пользования</div>*/}
                    </div>
                </div>
                <div className={style.personal__menu_icon}>
                    <ul className={style.requisites}>
                        <li>ООО ОП "БлокТехСервис"</li>
                        <li>ИНН: 5403047924</li>
                    </ul>
                    <div className={style.logo}><img src={logo_white} /></div>
                    {/*TODO: добавить позже как появяться ссылки*/}
                    {/*<div className={style.link}>*/}
                    {/*    <img src={vk}/>*/}
                    {/*    <img src={ok}/>*/}
                    {/*    <img src={tg}/>*/}
                    {/*</div>*/}
                </div>
            </div>

            <div className={style.personal__content}>
                <Routes>
                    <Route path="/*" index element={<MyServicesComponent />}></Route>
                    <Route path="balance" element={<BalanceComponent />}></Route>
                    <Route path="contract/:index" element={<ContractComponent />}></Route>

                    <Route path="payments-successful/:index" element={<PaymentsSuccessfulComponent />}></Route>
                    <Route path="payments-unsuccessful/:index" element={<PaymentsUnsuccessfulComponent />}></Route>
                </Routes>
            </div>
        </div>
    )
}

export default PersonalComponent
